import createSanityClient from "@sanity/client";

const options = {
    dataset: process.env.REACT_APP_SANITY_PROJECT_DATASET,
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    token: process.env.REACT_APP_SANITY_API_TOKEN,
    useCdn: true,
    apiVersion: "2021-03-25",
    ignoreBrowserTokenWarning: true,
};

export const sanityClient = createSanityClient(options);
