export const displayDeadline = (date) => {
    var f = new Date(date).getTime();
    let now = new Date().getTime();
    const compare = 1814400;

    if (f - compare > now) {
        return true;
    } else {
        return false;
    }
};
