const imageMeta = `
  "alt": coalesce(alt, asset->altText), 
  "url": asset->url, 
  asset, 
  crop, 
  customRatio, 
  hotspot, 
  "id": asset->assetId,
  "type": asset->mimeType, 
  "aspectRatio": asset->metadata.dimensions.aspectRatio, 
  "lqip": asset->metadata.lqip
`;

const ptContent = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      "url": @.url,
      "isButton": @.isButton,
      "styles": @.styles{style, isLarge, isBlock}
    }
  },
  _type == "photo" => {
    ${imageMeta}
  }
`;

export const FETCH_DEADLINE = `*[_type == "deadlineAlertSettings"][0]{ 
  date, 
  message 
}`;

export const FETCH_BUILDER_AD_POST = `*[_type == "promoSliderSettings"][0]{ 
  "slides": generalSlides[]{ 
    title, 
    image{  
      ${imageMeta}
    } 
  } 
}`;

export const FETCH_TM_AD_POST = `*[_type == "promoSliderSettings"][0]{ 
  "slides": tmSlides[]{ 
    title, 
    image{  
      ${imageMeta}
    } 
  } 
`;

export const FETCH_TRAINING_DATA = `*[_type == "resource"]{ 
  title, 
  "slug": slug.current,
  category[]->{
    title,
    "slug": slug.current
  },
  content[]{
    ${ptContent}
  },
  featuredImage{ 
    ${imageMeta}
  },
  excerpt,
  seo
}`;

export const FETCH_HELPER_DATA = `*[_type == "helperContent"]{ 
  title,
  slug, 
  content[]{ 
    ${ptContent}
  }
}`;

export const FETCH_PORTAL_ADS_WIDE = `*[_type == "programPortalAdSettings"][0]{ 
  wideAds[]{ 
    title, 
    image{  
      ${imageMeta}
    } 
  }
}`;

export const FETCH_PORTAL_ADS_SQUARE = `*[_type == "programPortalAdSettings"][0]{ 
  squareAds[]{ 
    title, 
    image{  
      ${imageMeta}
    } 
  }
}`;

export const getFetchPortalProgramsQuery = (tags) => {
    let tagConditions = "";
    if (tags && tags.length > 0) {
        tagConditions = " && (";
        for (let i = 0; i < tags.length; i++) {
            if (i === tags.length - 1) tagConditions += `"${tags[i]}" in tags[].value`;
            else tagConditions += `"${tags[i]}" in tags[].value || `;
        }
        tagConditions += ")";
    }

    return `*[_type == "program" && visibility == "visible"${tagConditions}]{ 
      name, 
      "slug": slug.current, 
      content[]{ 
        ${ptContent}
      },
      image{ 
        ${imageMeta}
      },
      programDownloads[]{
        name,
        "url": file.asset->url
      },
      excerpt,
      tags
    }`;
};
