import React from "react";

const Link = ({ link, children, ...rest }) => {
    return (
        <a href={link?.url} target={!link?.url.match("^mailto:") ? "_blank" : null} rel="noopener noreferrer" {...rest}>
            {link?.title || children}
        </a>
    );
};

export default Link;
