import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";
import { APP_TITLE } from "../../../util/constants";

import { sanityClient } from "../../../util/sanity";
import { FETCH_TRAINING_DATA } from "../../../lib/sanity";

import * as Sentry from "@sentry/react";

const Training = () => {
    const [trainingPosts, setTrainingPosts] = useState([]);
    const [trainingLoading, setTrainingLoading] = useState(true);

    useEffect(() => {
        sanityClient.fetch(FETCH_TRAINING_DATA).then((trainingPosts) => {
            setTrainingLoading(false);
            setTrainingPosts(trainingPosts);
        }).catch(err => {
            setTrainingLoading(false);
            Sentry.captureException(err);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="backgroundPage -mt-5 overflow-hidden pb-5" style={{ minHeight: "calc(100vh - 80px)" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{APP_TITLE} - Training & Events</title>
            </Helmet>
            <div className="flex flex-col h-full relative" style={{ zIndex: 2 }}>
                <main className="flex-1">
                    <div className="max-w-8xl flex flex-col h-full w-8xl mx-auto px-4 sm:px-6 lg:px-32">
                        <div className="flex flex-col lg:flex-row lg:items-center justify-between lg:space-x-5 w-full">
                            <p className="h1 font-title py-2 lg:py-4 text-white rounded-lg mt-2 filter drop-shadow-xl text-4xl">
                                Training & Events
                            </p>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 mt-5  rounded-lg min-h-300 gap-6">
                            {trainingPosts?.length > 0 ? (
                                <>
                                    {trainingPosts?.map((item, index) => {
                                        return (
                                            <Link
                                                key={index}
                                                className="border p-4 rounded-lg bg-white flex flex-col justify-start"
                                                to={{
                                                    pathname: `/resources/${item?.slug}`,
                                                    state: { item },
                                                }}
                                            >
                                                <div className="flex justify-between items-start relative ">
                                                    <div className="flex flex-col">
                                                        <p className="h1">{item?.title}</p>
                                                    </div>

                                                    {item?.featuredImage && (
                                                        <div
                                                            className="h-24 w-48 mb-2"
                                                            style={{
                                                                background: `url(${item?.featuredImage?.url}) center no-repeat`,
                                                            }}
                                                            alt={item?.featuredImage?.alt}
                                                        ></div>
                                                    )}
                                                </div>
                                                <p
                                                    className="mb-2 text-darkgray75 py-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.excerpt,
                                                    }}
                                                />
                                            </Link>
                                        );
                                    })}
                                </>
                            ) : trainingLoading ? (
                                <div className="flex w-full items-center justify-center col-span-3">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="flex w-full items-center justify-center col-span-3">
                                    <p className="font-title text-4xl text-white font-bold">
                                        {" "}
                                        No Training & Education posts found.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Training;
